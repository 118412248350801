<template>
  <div class="behaviorBar"></div>
</template>

<script>
export default {
  name: '',
  props: {
    data: Array
  },
  data() {
    return {
      dataSource: this.data,
      myChart: undefined,
      labelOption: {
        show: true,
        position: 'insideBottom',
        distance: 10,
        align: 'left',
        verticalAlign: 'middle',
        rotate: 90,
        formatter: '{a}', // '{c}  {name|{a}}',
        fontSize: 10,
        rich: {
          name: {}
        }
      }
    }
  },
  watch: {
    data: {
      deep: true, // 深度监听
      handler: function (newVal) {
        this.dataSource = newVal
        const option = this.myChart.getOption()
        option.series[1].data = this.dataSource
        this.myChart.setOption(option)
      }
    }
  },
  methods: {
    setChart() {
      let option = {
        // title: {
        //   /*text: '【预警批次数】',*/
        //   textStyle: {
        //     color: '#75deef',
        //     fontSize: 12,
        //     fontWeight: 'normal'
        //   },
        //   left: '40%'
        // },
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     // 坐标轴指示器，坐标轴触发有效
        //     type: 'none' // 默认为直线，可选为：'line' | 'shadow'
        //   },
        //   backgroundColor: '#11367a',
        //   formatter: '{b}:{c1}',
        //   textStyle: {
        //     color: '#6dd0e3',
        //     fontSize: 10
        //   }
        // },
        // grid: {
        //   top: '10%',
        //   left: '1%',
        //   right: '5%',
        //   bottom: '4%',
        //   containLabel: true
        // },
        // xAxis: {
        //   type: 'category',
        //   axisLabel: {
        //     interval: 0,
        //     color: '#75deef',
        //     fontSize: 9,
        //     showMinLabel: true
        //   },
        //   axisLine: {
        //     lineStyle: {
        //       color: '#1a3c58',
        //       width: 2
        //     }
        //   },
        //   axisTick: { show: false },

        //   data: ['新增会员', '续费会员']
        // },
        // yAxis: [
        //   {
        //     type: 'value',
        //     min: 0,
        //     max: 200000,
        //     axisLabel: {
        //       color: '#75deef',
        //       fontSize: 9,
        //       showMaxLabel: false
        //     },
        //     name: '(人次)',
        //     nameGap: -5,
        //     nameTextStyle: {
        //       color: '#75deef',
        //       fontSize: 9,
        //       align: 'right',
        //       padding: [0, 6, 0, 0]
        //     },
        //     axisTick: {
        //       length: 3
        //     },
        //     axisLine: {
        //       lineStyle: {
        //         color: '#1a3c58'
        //       }
        //     },

        //     splitLine: { show: false }
        //   }
        // ],
        // series: [
        //   {
        //     name: '新增会员',
        //     type: 'bar',
        //     barWidth: 12,
        //     barGap: '-100%',
        //     itemStyle: {
        //       color: '#252448'
        //     },
        //     data: [200000, 200000, 200000, 200000, 200000, 200000, 200000, 200000]
        //   },
        //   {
        //     name: '续费会员',
        //     type: 'bar',
        //     barWidth: 12,
        //     barGap: '-100%',
        //     itemStyle: {
        //       color: '#e062ae'
        //     },
        //     data: this.data
        //   }
        // ]

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          height: '70%', // adjust the grid height
          top: 50,
          bottom: 50,
          left: 50,
          right: 50,
          containLabel: true
        },

        // legend: {
        //   data: ['Forest', 'Steppe', 'Desert', 'Wetland'],

        // },
        // toolbox: {
        //   show: true,
        //   orient: 'vertical',
        //   left: 'right',
        //   top: 'center',
        //   feature: {
        //     mark: { show: true },
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ['line', 'bar', 'stack'] },
        //     restore: { show: true },
        //     saveAsImage: { show: true }
        //   }
        // },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            axisLabel: {
              // fontSize: 8,
              color: '#fff' // 设置水平轴字体颜色为红色
            },

            axisLine: {
              lineStyle: {
                color: '#323232' // 设置水平轴线条颜色为红色
              }
            },
            data: ['2022', '2023', '2024']
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: '#323232' // 设置线颜色为透明
              }
            },
            axisLabel: {
              // fontSize: 8,
              color: '#fff' // 设置水平轴线条颜色为红色
            }
            // axisLine: {
            //   lineStyle: {
            //     color: '#323232' // 设置水平轴线条颜色为红色
            //   }
            // }
          }
        ],
        series: [
          {
            name: '新增会员',
            type: 'bar',
            barGap: 0,
            color: '#37b48d',
            barWidth: 30,
            label: this.labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [3320, 4332, 5301]
          },

          {
            name: '续费会员',
            type: 'bar',
            color: '#748ede',
            barWidth: 30,

            label: this.labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [4220, 5882, 3991]
          }
        ]
      }

      this.myChart = this.$echarts(this.$el)

      this.myChart.clear()
      this.myChart.resize()
      this.myChart.setOption(option)
    }
  },
  mounted() {
    this.setChart()
  }
}
</script>

<style lang="less" scoped>
.behaviorBar {
  height: 90%;
}
</style>
